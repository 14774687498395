@if (edit()) {
  <div class="row justify-content-end">
    <div class="col-auto mt-2 mt-lg-0">
      <button
        class="btn btn-outline-medium"
        type="button"
        routerLink="/{{ rootLink() }}/view/{{ itemLink() }}{{ endLink() }}"
        [disabled]="addLoading() || editLoading() || deleteLoading()"
      >
        <i class="fa-light fa-undo-alt me-0 me-md-2"></i>
        <span class="d-none d-md-inline">{{ 'global.Annuler' | translate }}</span>
      </button>
    </div>
    @if (!disableDelete()) {
      <div class="col-auto mt-2 mt-lg-0">
        <button class="btn btn-secondary" type="button" (click)="deleteClicked.emit()" [disabled]="deleteLoading()">
          <i class="fa-light fa-trash me-0 me-md-2"></i>
          <span class="d-none d-md-inline">{{ 'global.Supprimer' | translate }}</span>
          @if (deleteLoading()) {
            <i class="fa-light fa-spinner spinning ms-2"></i>
          }
        </button>
      </div>
    }
    <div class="col-auto mt-2 mt-lg-0">
      <button class="btn btn-primary" type="submit" (click)="saveEditClicked.emit()" [disabled]="editLoading()">
        <i class="fa-light fa-save me-0 me-md-2"></i>
        <span class="d-none d-md-inline">{{ 'global.Valider' | translate }}</span>
        @if (editLoading()) {
          <i class="fa-light fa-spinner spinning ms-2"></i>
        }
      </button>
    </div>
  </div>
}

@if (add()) {
  <div class="row justify-content-end">
    <div class="col-auto mt-2 mt-md-0">
      <button
        class="btn btn-outline-medium"
        type="button"
        [routerLink]="backLinkOnAdd() === undefined ? ['/' + rootLink()] : backLinkOnAdd()"
        [disabled]="addLoading()"
      >
        <i class="fa-light fa-undo-alt me-0 me-md-2"></i>
        <span class="d-none d-md-inline">{{ 'global.Annuler' | translate }}</span>
      </button>
    </div>
    <div class="col-auto mt-2 mt-md-0">
      <button class="btn btn-primary" type="submit" (click)="addClicked.emit()" [disabled]="addLoading()">
        <i class="fa-light fa-save me-0 me-md-2"></i>
        <span class="d-none d-md-inline">{{ 'global.Valider' | translate }}</span>
        @if (addLoading()) {
          <i class="fa-light fa-spinner spinning ms-2"></i>
        }
      </button>
    </div>
  </div>
}
