import { Component, OnInit, output, input } from '@angular/core';

@Component({
    selector: 'hiji-bottom-buttons',
    templateUrl: './bottom-buttons.component.html',
    styleUrls: ['./bottom-buttons.component.scss'],
    standalone: false
})
export class BottomButtonsComponent implements OnInit {
  readonly rootLink = input<string>(undefined);
  readonly endLink = input<string>(undefined);
  readonly itemLink = input<string>(undefined);
  readonly backLinkOnAdd = input<string>(undefined);
  readonly edit = input<boolean>(undefined);
  readonly add = input<boolean>(undefined);
  readonly addLoading = input<boolean>(undefined);
  readonly editLoading = input<boolean>(undefined);
  readonly deleteLoading = input<boolean>(undefined);
  readonly disableDelete = input<boolean>(false);
  readonly deleteClicked = output<void>();
  readonly saveEditClicked = output<void>();
  readonly addClicked = output<void>();
  constructor() {}

  ngOnInit(): void {}
}
