import { Component, EventEmitter, OnInit, Output, input } from '@angular/core';

@Component({
  selector: 'hiji-top-buttons',
  templateUrl: './top-buttons.component.html',
  styleUrls: ['./top-buttons.component.scss'],
  standalone: false,
})
export class TopButtonsComponent implements OnInit {
  readonly rootLink = input<string>(undefined);
  readonly endLink = input<string>(undefined);
  readonly itemLink = input<string>(undefined);
  readonly backLink = input<string>(undefined);
  readonly edit = input<boolean>(undefined);
  readonly add = input<boolean>(undefined);
  readonly hideBackButton = input<boolean>(undefined);
  @Output()
  duplicate = new EventEmitter<void>(); //not migrated to output() because of https://github.com/angular/angular/issues/54837
  allowEdit = input(true);
  allowDuplicate = input(true);

  isAdminOrGestionnaire: boolean;

  constructor() {}

  ngOnInit(): void {}
}
